import React from 'react';
import NavigationBar from './NavigationBar';
import { getUserPageAccess } from '../actions/constants';
import { Link } from 'react-router-dom';
import Globalize from '../actions/languages/Globalize';

class Greetings extends React.Component {
  render() {
    return (
      <div id="welcome-screen">
        <div>
          <NavigationBar loggedStatus={true} user={this.props.user} />
          <div className="index-page">
            <h1 className="text-center margin-zero">
              {Globalize.getString('welcome_string')}
            </h1>
            <div className="index-page-links">
              {this.props.user_roles !== undefined &&
                Array.isArray(this.props.user_roles) &&
                this.props.user_roles.length > 0 && (
                <ul>
                  {getUserPageAccess(this.props.user_roles, [
                    'suppliers_view',
                  ]) && (
                    <li>
                      <Link to="/suppliers">
                        <i className="fa fa-building-o fa-fw" />{' '}
                        {Globalize.getString('suppliers')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'catalogs_view',
                  ]) && (
                    <li>
                      <a href="/v3/cataloging/catalogs">
                        <i className="fa fa-database fa-fw" />{' '}
                        {Globalize.getString('catalogs')}
                      </a>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'collections_view',
                  ]) && (
                    <li>
                      <Link to="/collections">
                        <i className="fa fa-database fa-fw" />{' '}
                        {Globalize.getString('collections')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'hvf_admin',
                  ]) && (
                    <li>
                      <Link to="/hvf-admin">
                        <i className="fa fa-filter fa-fw" />{' '}
                        {Globalize.getString('hvf_admin')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'community_moderation',
                  ]) && (
                    <li>
                      <Link to="/community/moderation">
                        <i className="fa fa-file fa-fw" />{' '}
                        {Globalize.getString('community_moderation')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'community_upload',
                  ]) && (
                    <li>
                      <Link to="/community/upload">
                        <i className="fa fa-upload" />{' '}
                        {Globalize.getString('community_upload')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'widgets_view',
                  ]) && (
                    <li>
                      <Link to="/widgets-group">
                        <i className="fa fa-list fa-fw" />{' '}
                        {Globalize.getString('widget_group')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'landing_pages_view',
                  ]) && (
                    <li>
                      <Link to="/landing-pages">
                        <i className="fa fa-list fa-fw" />{' '}
                        {Globalize.getString('landing_pages')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'orders_view',
                  ]) && (
                    <li>
                      <Link to="/orders">
                        <i className="fa fa-shopping-cart fa-fw" />{' '}
                        {Globalize.getString('orders')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'resellers_view',
                  ]) && (
                    <li>
                      <a href="/v3/reseller">
                        <i className="fa fa-building-o fa-fw" />{' '}
                        {Globalize.getString('reseller')}
                      </a>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'supplier_payments_view',
                  ]) && (
                    <li>
                      <Link to="/supplier/payment">
                        <i className="fa fa-building-o fa-fw" />{' '}
                        {Globalize.getString('supplier_payment')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'app_banners_view',
                  ]) && (
                    <li>
                      <Link to="/app-banners">
                        <i className="fa fa-image fa-fw" />{' '}
                        {Globalize.getString('app_banners')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'cod_verification_view',
                  ]) && (
                    <li>
                      <Link to="/codverification">
                        <i className="fa fa-file-excel-o fa-fw" />{' '}
                        {Globalize.getString('cod_verification')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'trends_view',
                  ]) && (
                    <li>
                      <Link to="/dailytrends">
                        <i className="fa fa-file-excel-o fa-fw" />{' '}
                        {Globalize.getString('daily_trends')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'bulk_cancellations_view',
                  ]) && (
                    <li>
                      <Link to="/bulkcancellation">
                        <i className="fa fa-file-excel-o fa-fw" />{' '}
                        {Globalize.getString('bulk_cancellation')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'firebase_admin',
                  ]) && (
                    <li>
                      <Link to="/firebase/admin">
                        <i className="fa fa-user-circle-o" />{' '}
                        {Globalize.getString('firebase_admin')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'supplier_invoice',
                  ]) && (
                    <li>
                      <Link to="/tax-invoices">
                        <i className="fa fa-file-excel-o fa-fw" />{' '}
                        {Globalize.getString('tax_invoices')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'supplier_penalty',
                  ]) && (
                    <li>
                      <Link to="/supplier-penalty-old">
                        <i className="fa fa-file-excel-o fa-fw" />{' '}
                        {Globalize.getString('supplier_penalty')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'supply_tasks',
                  ]) && (
                    <li>
                      <Link to="/supply-tasks">
                        <i className="fa fa-file-excel-o fa-fw" />{' '}
                        {Globalize.getString('supply_tasks')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'user_access_admin',
                  ]) && (
                    <li>
                      <Link to="/admin-access">
                        <i className="fa fa-file-excel-o fa-fw" />{' '}
                        {Globalize.getString('admin_access')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'admin_notice',
                  ]) && (
                    <li>
                      <Link to="/admin-notices">
                        <i className="fa fa-file-excel-o fa-fw" />{' '}
                        {Globalize.getString('admin_notices')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'catalog_rating_review',
                  ]) && (
                    <li>
                      <Link to="/review-ratings">
                        <i className="fa fa-image fa-fw" />{' '}
                        {Globalize.getString('review_ratings')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'video_influencer_review',
                  ]) && (
                    <li>
                      <Link to="/video-influencer">
                        <i className="fa fa-image fa-fw" />{' '}
                        {Globalize.getString('video_influencer')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'dynamic_webview',
                  ]) && (
                    <li>
                      <Link to="/dynamic-webview">
                        <i className="fa fa-image fa-fw" />{' '}
                        {Globalize.getString('dynamic_webview')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'catalog_rating_file_upload',
                  ]) && (
                    <li>
                      <Link to="/catalog-rating-rewards">
                        <i className="fa fa-file-excel-o fa-fw" />{' '}
                        {Globalize.getString('tinder_ratings')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'meesho_reseller_rewards',
                  ]) && (
                    <li>
                      <Link to="/meesho-rewards">
                        <i className="fa fa-file-excel-o fa-fw" />{' '}
                        {Globalize.getString('meesho_rewards')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'mentor_performance_dashboard',
                  ]) && (
                    <li>
                      <Link to="/mentor-dashboard">
                        <i className="fa fa-file-excel-o fa-fw" />{' '}
                        {Globalize.getString('mentor_dashboard')}
                      </Link>
                    </li>
                  )}
                  {/* {getUserPageAccess(this.props.user_roles, ['supplier_inventory_update']) && <li>
                  <Link to="/supplier-inventory-update">
                    <i className="fa fa-file-excel-o fa-fw"/> {Globalize.getString('supplier_inventory_update')}
                  </Link>
                </li>} */}
                  {getUserPageAccess(this.props.user_roles, [
                    'supplier_manifest',
                  ]) && (
                    <li>
                      <Link to="/supplier-manifest">
                        <i className="fa fa-file-excel-o fa-fw" />{' '}
                        {Globalize.getString('supplier_manifest')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'files_uploading',
                  ]) && (
                    <li>
                      <Link to="/files-uploading">
                        <i className="fa fa-file-excel-o fa-fw" />{' '}
                        {Globalize.getString('files_uploading')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'visual_search',
                  ]) && (
                    <li>
                      <a href="/v3/discovery/visual-search">
                        <i className="fa fa-file-excel-o fa-fw" />{' '}
                        {Globalize.getString('visual_search')}
                      </a>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'update_pow_static_assets',
                  ]) && (
                    <li>
                      <a href="/v3/discovery/update-static-assets">
                        <i className="fa fa-file-excel-o fa-fw" />{' '}
                        {Globalize.getString('update_pow_static_assets')}
                      </a>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'marketing_dashboard',
                  ]) && (
                    <li>
                      <Link to="/marketing-dashboard">
                        <i className="fa fa-file-excel-o fa-fw" />{' '}
                        {Globalize.getString('marketing_dashboard')}
                      </Link>
                    </li>
                  )}

                  {getUserPageAccess(this.props.user_roles, [
                    'ab_experiment',
                  ]) && (
                    <li>
                      <Link to="/ab-experiment">
                        <i className="fa fa-rupee fa-fw" />{' '}
                        {Globalize.getString('ab_experiment')}
                      </Link>
                    </li>
                  )}

                  {getUserPageAccess(this.props.user_roles, [
                    'abacus',
                  ]) && (
                    <li>
                      <a href="/v3/discovery/abacus">
                        <i className="fa fa-rupee fa-fw" />
                        {Globalize.getString('abacus')}
                      </a>
                    </li>
                  )}

                  {getUserPageAccess(this.props.user_roles, [
                    'audience_system',
                  ]) && (
                    <li>
                      <Link to="/audience-system">
                        <i className="fa fa-user-circle-o" />{' '}
                        {Globalize.getString('audience_system')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'pricing_deals',
                  ]) && (
                    <li>
                      <Link to="/deals">
                        <i className="fa fa-user-circle-o" />{' '}
                        {Globalize.getString('deals')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'price_scheduling',
                  ]) && (
                    <li>
                      <Link to="/price-scheduling">
                        <i className="fa fa-user-circle-o" />{' '}
                        {Globalize.getString('price_scheduling')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'sms_portal_view',
                  ]) && (
                    <li>
                      <a href="/v3/comms/sms">
                        <i className="fa fa-database fa-fw" />{' '}
                        {Globalize.getString('sms_portal')}
                      </a>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'notification_portal_view',
                  ]) && (
                    <li>
                      <a href="/v3/comms/notification">
                        <i className="fa fa-database fa-fw" />{' '}
                        {Globalize.getString('notification_portal')}
                      </a>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'whatsapp_notifications_view',
                  ]) && (
                    <li>
                      <a href="/v3/comms/whatsapp">
                        <i className="fa fa-database fa-fw" />{' '}
                        {Globalize.getString('whatsapp_notification')}
                      </a>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'email_notifications'
                  ]) && (
                    <li>
                      <a href="/v2/email-notifications">
                        <i className="fa fa-database fa-fw" />
                        {Globalize.getString('email_notifications')}
                      </a>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'notification_all_campaigns',
                  ]) && (
                    <li>
                      <a href="/v3/comms/all-notifications">
                        <i className="fa fa-database fa-fw" />{' '}
                        {Globalize.getString('all_notification_campaigns')}
                      </a>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'make_calls_view',
                  ]) && (
                    <li>
                      <Link to="/make-calls">
                        <i className="fa fa-database fa-fw" />{' '}
                        {Globalize.getString('make_calls')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'gamification',
                  ]) && (
                    <li>
                      <Link to="/gamification">
                        <i className="fa fa-user-circle-o" />{' '}
                        {Globalize.getString('gamification')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'referral_campaign',
                  ]) && (
                    <li>
                      <Link to="/referral_campaign">
                        <i className="fa fa-user-circle-o" /> Referral
                          Campaign
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'referral_program',
                  ]) && (
                    <li>
                      <Link to="/referral_program">
                        <i className="fa fa-file-excel-o fa-fw" />{' '}
                        {Globalize.getString('referral_program')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    's3_file_uploader',
                  ]) && (
                    <li>
                      <Link to="/s3-file-uploader">
                        <i className="fa fa-user-circle-o" />{' '}
                        {Globalize.getString('s3_file_uploader')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'generic_excel_upload',
                  ]) && (
                    <li>
                      <Link to="/excel-upload">
                        <i className="fa fa-file-excel-o fa-fw" />{' '}
                        {Globalize.getString('excel_upload')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'rule_engine',
                  ]) && (
                    <li>
                      <Link to="/rule-engine">
                        <i className="fa fa-file-excel-o fa-fw" />{' '}
                        {Globalize.getString('rule_engine')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'search_ops',
                  ]) && (
                    <li>
                      <a href="/v3/discovery/search-ops">
                        <i className="fa fa-search fa-fw" />{' '}
                        {Globalize.getString('search_ops')}
                      </a>
                    </li>
                  )}

                  {getUserPageAccess(this.props.user_roles, [
                    'category_navigation_trees',
                  ]) && (
                    <li>
                      <Link to="/category-navigation">
                        <i className="fa fa-file-excel-o fa-fw" />{' '}
                        {Globalize.getString('category_navigation_trees')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'clp_admin',
                  ]) && (
                    <li>
                      <Link to="/clp-admin">
                        <i className="fa fa-file-excel-o fa-fw" />{' '}
                        {Globalize.getString('clp_admin')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'top_nav_admin',
                  ]) && (
                    <li>
                      <Link to="/top-nav-admin">
                        <i className="fa fa-file-excel-o fa-fw" />{' '}
                        {Globalize.getString('top_nav_admin')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'sort_and_filter',
                  ]) && (
                    <li>
                      <Link to="/sort-and-filter">
                        <i className="fa fa-file-excel-o fa-fw" />{' '}
                        {Globalize.getString('sort_and_filter')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'clp_products_view',
                  ]) && (
                    <li>
                      <Link to="/clp/products">
                        <i className="fa fa-file-excel-o fa-fw" /> CLP Filters
                          Validation
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'agent_dashboard',
                  ]) && (
                    <li>
                      <Link to="/agent-dashboard/search">
                        <i className="fa fa-file-excel-o fa-fw" />{' '}
                        {Globalize.getString('agent_dashboard')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'uat_catalogs',
                  ]) && (
                    <li>
                      <Link to="/supply-tasks/uat-catalogs">
                        <i className="fa fa-file-excel-o fa-fw" />{' '}
                        {Globalize.getString('uat_catalogs')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'supplier_finance',
                  ]) && (
                    <li>
                      <Link to="/verify-kyc">
                        <i className="fa fa-database fa-fw" />{' '}
                        {Globalize.getString('supplier_finance')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'return_reasons',
                  ]) && (
                    <li>
                      <Link to="/fulfilment">
                        <i className="fa fa-database fa-fw" />{' '}
                        {Globalize.getString('return_reasons')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'legal_and_policies',
                  ]) && (
                    <li>
                      <Link to="/legal-and-policies">
                        <i className="fa fa-database fa-fw" />
                          Legal and Policies
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'supplier_ads_task',
                  ]) && (
                    <li>
                      <Link to="/ads-task">
                        <i className="fa fa-database fa-fw" />
                          Ads Task
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'supplier_ads_task_mma_deep_link',
                  ]) && (
                    <li>
                      <Link to="/mma-task">
                        <i className="fa fa-database fa-fw" />
                          MMA task
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'live_stream_view',
                  ]) && (
                    <li>
                      <Link to="/live-commerce">
                        <i className="fa fa-database fa-fw" />
                        {Globalize.getString('live_commerce')}
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, ['pow']) && (
                    <li>
                      <Link to="/pow">
                        <i className="fa fa-database fa-fw" />
                          POW
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'dispaly_ads_section',
                  ]) && (
                    <li>
                      <Link to="/campaign-dashboard">
                        <i className="fa fa-database fa-fw" />
                          Display Ads
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'dispaly_ads_section'
                  ])&& (
                    <li>
                      <Link to="/non-seller-portal">
                        <i className="fa fa-database fa-fw" />
                        Non Seller Portal
                      </Link>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'promotions_view',
                  ]) && (
                    <li>
                      <a href="/v3/promotions">
                        <i className="fa fa-percent fa-fw" />
                        {Globalize.getString('promotions')}
                      </a>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'agency_panel_account_management',
                  ]) && (
                    <li>
                      <a href="/v2/agency-account-management">
                        <i className="fa fa-database fa-fw" />
                        {Globalize.getString(
                          'agency_panel_account_management'
                        )}
                      </a>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'admin_queries',
                  ]) && (
                    <li>
                      <a href="/v2/admin-queries">
                        <i className="fa fa-database fa-fw" />
                        {Globalize.getString('admin_queries')}
                      </a>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'agent_queries',
                  ]) && (
                    <li>
                      <a href="/v2/agent-queries">
                        <i className="fa fa-database fa-fw" />
                        {Globalize.getString('agent_queries')}
                      </a>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'ds_tagging',
                  ]) && (
                    <li>
                      <a href="/v2/ds-tagging">
                        <i className="fa fa-database fa-fw" />
                        {Globalize.getString('ds_tagging')}
                      </a>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'bulk_password_reset',
                  ]) && (
                    <li>
                      <a href="/v2/password-reset-bulk">
                        <i className="fa fa-database fa-fw" />
                        {Globalize.getString('bulk_password_reset')}
                      </a>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'bulk_gstin_invalidate',
                  ]) && (
                    <li>
                      <a href="/v2/gstin-invalidate-bulk">
                        <i className="fa fa-database fa-fw" />
                        {Globalize.getString('bulk_gstin_invalidate')}
                      </a>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'supplier_panel_access_control',
                  ]) && (
                    <li>
                      <a href="/v3/cataloging/role-based-access">
                        <i className="fa fa-database fa-fw" />
                        {Globalize.getString('supplier_role_based_access')}
                      </a>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'send_supplier_email',
                  ]) && (
                    <li>
                      <a href="/v3/cataloging/supplier-email">
                        <i className="fa fa-database fa-fw" />
                        {Globalize.getString('send_supplier_email')}
                      </a>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'zero_order_audience',
                  ]) && (
                    <li>
                      <a href="/v3/discovery/zero-order-audience">
                        <i className="fa fa-users fa-fw" />{' '}
                        {Globalize.getString('zero_order_audience')}
                      </a>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'single_product_details_admin',
                  ]) && (
                    <li>
                      <a href="/v3/discovery/single-product-details">
                        <i className="fa fa-product-hunt fa-fw" />{' '}
                        {Globalize.getString('single_product_details')}
                      </a>
                    </li>
                  )}

                  {getUserPageAccess(this.props.user_roles, [
                    'widgets_view',
                  ]) && (
                    <li>
                      <a href="/v3/discovery/debug-your-widget-group">
                        <i className="fa fa-users fa-fw" />{' '}
                        {Globalize.getString('debug_your_widget_group')}
                      </a>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'reel_moderation_dashboard',
                  ]) && (
                    <li>
                      <a href="/v3/comms/reel-moderation">
                        <i className="fa fa-users fa-fw" />{' '}
                        {Globalize.getString('reel_moderation_dashboard')}
                      </a>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'seller_bank_verification_request_view'
                  ]) && <li>
                    <a href="/v3/cataloging/seller-bank-verification">
                      <i className="fa fa-database fa-fw" />{' '}
                      {Globalize.getString('seller_bank_verification')}
                    </a>
                  </li>}
                  {getUserPageAccess(this.props.user_roles, [
                    'widgets_view',
                  ]) && (
                    <li>
                      <a href="/v3/discovery/widget-forge">
                        <i className="fa fa-gear fa-fw" />{' '}
                        {Globalize.getString('sdui_widget_forge')}
                      </a>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'widgets_view',
                  ]) && (
                    <li>
                      <a href="/v3/discovery/sdui/widget-preview">
                        <i className="fa fa-camera-retro fa-fw" />{' '}
                        {Globalize.getString('sdui_widget_preview')}
                      </a>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'sdui_widget_template',
                  ]) && (
                    <li>
                      <a href="/v3/discovery/sdui/templates">
                        <i className="fa fa-database fa-fw" />{' '}
                        {Globalize.getString('sdui_widget_template')}
                      </a>
                    </li>
                  )}
                  {getUserPageAccess(this.props.user_roles, [
                    'pow_products_file_upload',
                  ]) && (
                    <li>
                      <a href="/v3/comms/plp-management">
                        <i className="fa fa-gear fa-fw" />{' '}
                        {Globalize.getString('plp_management_tool')}
                      </a>
                    </li>
                  )}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Greetings;
