import React from 'react';
import { Link } from 'react-router-dom';
import { browserHistory } from '@browserHistory';
import queryString from 'query-string';
import { logout } from './../../../actions/authActions';
import { excelRouteChange } from './../../../actions/excelUpload';
import { getUserPageAccess } from '../../../actions/constants';
import Globalize from '../../../actions/languages/Globalize';

import { connect } from 'react-redux';

function mapDispatchToProps(dispatch) {
  return {
    onExcelRouteChange: () => {
      dispatch(excelRouteChange());
    },
  };
}

class DashBoardNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: '',
      catalog: '',
      supplier: '',
      payment: '',
      reseller: '',
      sidebarOpen: this.props?.sidebarOpen || true,
      filter_text: 'Hide Filter',
      filterOpen: true,
      order_page: false,
      catalog_page: false,
      order_count: 0,
      product_count: 0,
      orderFilter: false,
    };
  }

  logout(e) {
    e.preventDefault();
    logout()().then((res) => {
      browserHistory.push('/login');
    });
  }

  UNSAFE_componentWillMount() {
    let url = location.pathname;
    if (
      url.includes('order') &&
      !url.includes('/suborder-sla-update') &&
      !url.includes('/suborder-cancel-date-update') &&
      !url.includes('/order-shipped-delivered')
    ) {
      this.setState({ order: 'active' });
      if (this.props.orderPage !== undefined) {
        this.setState({ order_page: true });
      }
    } else if (
      url.includes('catalog') &&
      !url.includes('/catalog-videos') &&
      !url.includes('/supply-tasks') &&
      !url.includes('/catalog-rating-rewards') &&
      !url.includes('/catalog-product-map')
    ) {
      this.setState({ catalog: 'active' });
      if (this.props.catalogPage !== undefined) {
        this.setState({ catalog_page: true });
      }
    } else if (
      url.includes('supplier') &&
      !url.includes('/supplier-inventory-update') &&
      !url.includes('/supplier/payment') &&
      !url.includes('supplier-penalty') &&
      !url.includes('/international-supplier/payment') &&
      !url.includes('supplier-manifest') &&
      !url.includes('product-supplier-price-update')
    ) {
      this.setState({ supplier: 'active' });
    } else if (url.includes('reseller')) {
      this.setState({ reseller: 'active' });
    }
    const parsed = queryString.parse(location.search);
    if (
      parsed.filter_hidden !== undefined &&
      (parsed.filter_hidden === 'true' || parsed.filter_hidden)
    ) {
      this.setState({ filter_text: 'Show Filter', filterOpen: false });
    }
    if (
      parsed.sidebar_hidden !== undefined &&
      (parsed.sidebar_hidden === 'true' || parsed.sidebar_hidden)
    ) {
      this.setState({ sidebarOpen: !this.state.sidebarOpen });
    }
  }

  toggleSideBar() {
    this.setState({ sidebarOpen: !this.state.sidebarOpen }, function () {
      this.props.toggleSideBar();
    });
  }

  toggleFilter() {
    if (this.state.order_page || this.state.catalog_page) {
      if (this.state.filterOpen) {
        this.setState(
          { filter_text: 'Show Filter', filterOpen: false },
          function () {
            this.props.toggleFilter();
          }
        );
      } else {
        this.setState(
          { filter_text: 'Hide Filter', filterOpen: true },
          function () {
            this.props.toggleFilter();
          }
        );
      }
    }
  }

  onResExcelDownload() {
    const { onExcelRouteChange } = this.props;
    onExcelRouteChange();
  }

  showOrderCount(order_count, product_count, orderFilter) {
    this.setState({
      order_count: order_count,
      product_count: product_count,
      orderFilter: orderFilter,
    });
  }

  render() {
    let url = location.pathname;
    let reseller = '';
    let collections = '';
    let communityModeration = '';
    let supplierPayment = '';
    let banners = '';
    let cod_verification = '';
    let daily_trends = '';
    let bulk_cancellation = '';
    let tax_invoices = '';
    let supplier_penalty = '';
    let supply_tasks = '';
    let firebase_admin = '';
    let admin_access = '';
    let admin_notice = '';
    let review_ratings = '';
    let catalog_ratings = '';
    let reseller_tiering = '';
    let supplier_inventory_update = '';
    let supplier_manifest = '';
    let widgets = '';
    let landing_pages = '';
    let files_uploading = '';
    let visual_search = '';
    let marketing_dashboard = '';
    let ab_experiment = '';
    let audience_system = '';
    let deals = '';
    let price_scheduling = '';
    let sms_portal = '';
    let notification_portal = '';
    let make_calls = '';
    let gamification = '';
    let referral_campaign = '';
    let referral_program = '';
    let add_attributes_view = '';
    let video_influencer = '';
    let dynamic_webview = '';
    let s3_file_uploader = '';
    let rule_engine = '';
    let category_navigation_trees = '';
    let clp_admin = '';
    let clp_products_view = '';
    let top_nav_admin = '';
    let sort_and_filter = '';
    let agent_dashboard = '';
    let communityUpload = '';
    let uat_catalogs = '';
    let hvf_admin = '';
    let farmiso_dealer_activate = '';
    let supplier_finance = '';
    let fulfilment = '';
    let whatsapp_notifications = '';
    let all_campaigns = '';
    let all_notification_campaigns = '';
    let supplier_ads_task = '';
    let mma_link='';
    let dispaly_ads_section = '';
    let live_stream_view = '';
    let non_seller_portal='';

    if (url.includes('reseller')) {
      reseller = 'active';
    } else if (url.includes('collection') && !url.includes('/supply-tasks')) {
      collections = 'active';
    } else if (
      url.includes('/supplier/payment') ||
      url.includes('/international-supplier/payment')
    ) {
      supplierPayment = 'active';
    } else if (url.includes('/banner')) {
      banners = 'active';
    } else if (url.includes('/codverification')) {
      cod_verification = 'active';
    } else if (url.includes('/dailytrends')) {
      daily_trends = 'active';
    } else if (url.includes('/bulkcancellation')) {
      bulk_cancellation = 'active';
    } else if (url.includes('/firebase')) {
      firebase_admin = 'active';
    } else if (url.includes('/tax-invoices')) {
      tax_invoices = 'active';
    } else if (url.includes('/supplier-penalty-old')) {
      supplier_penalty = 'active';
    } else if (
      url.includes('/supply-tasks') &&
      !url.includes('/supply-tasks/uat-catalogs')
    ) {
      supply_tasks = 'active';
    } else if (url.includes('/admin-access')) {
      admin_access = 'active';
    } else if (url.includes('/admin-notices')) {
      admin_notice = 'active';
    } else if (url.includes('/review-ratings')) {
      review_ratings = 'active';
    } else if (url.includes('/catalog-rating-rewards')) {
      catalog_ratings = 'active';
    } else if (url.includes('/meesho-rewards')) {
      reseller_tiering = 'active';
    } else if (url.includes('/supplier-manifest')) {
      supplier_manifest = 'active';
    } else if (url.includes('/widget')) {
      widgets = 'active';
    } else if (url.includes('/landing-page')) {
      landing_pages = 'active';
    } else if (
      url.includes('/files-uploading') ||
      url.includes('/catalog-videos') ||
      url.includes('/reverse-tinder') ||
      url.includes('/china-vsku-upload') ||
      url.includes('/taxonomy-product-file-upload') ||
      url.includes('/catalog-product-map') ||
      url.includes('/product-delete') ||
      url.includes('/supplier-penalty') ||
      url.includes('/tinder-taxonomy-product-file-upload') ||
      url.includes('/product-supplier-price-update') ||
      url.includes('/credits-winner-infograph') ||
      url.includes('/sub-order-carrier-change')
    ) {
      files_uploading = 'active';
    } else if (url.includes('/visual-search')) {
      visual_search = 'active';
    } else if (url.includes('/marketing-dashboard')) {
      marketing_dashboard = 'active';
    } else if (url.includes('/ab-experiment')) {
      ab_experiment = 'active';
    } else if (url.includes('/audience-system')) {
      audience_system = 'active';
    } else if (url.includes('/deals')) {
      deals = 'active';
    } else if (url.includes('price-scheduling')) {
      price_scheduling = 'active';
    } else if (url.includes('/sms-portal')) {
      sms_portal = 'active';
    } else if (url.includes('/notification-portal')) {
      notification_portal = 'active';
    } else if (url.includes('/v2/whatsapp-notification')) {
      whatsapp_notifications = 'active';
    } else if (url.includes('/make-calls')) {
      make_calls = 'active';
    } else if (url.includes('gamification')) {
      gamification = 'active';
    } else if (url.includes('referral_campaign')) {
      referral_campaign = 'active';
    } else if (url.includes('referral_program')) {
      referral_program = 'active';
    } else if (url.includes('/video-influencer')) {
      video_influencer = 'active';
    } else if (url.includes('s3-file-uploader')) {
      s3_file_uploader = 'active';
    } else if (url.includes('/add-attributes-view')) {
      add_attributes_view = 'active';
    } else if (url.includes('/dynamic-webview')) {
      dynamic_webview = 'active';
    } else if (url.includes('/rule-engine')) {
      rule_engine = 'active';
    } else if (url.includes('/category-navigation')) {
      category_navigation_trees = 'active';
    } else if (url.includes('/clp-admin')) {
      clp_admin = 'active';
    } else if (url.includes('/clp-products')) {
      clp_products_view = 'active';
    } else if (url.includes('/top-nav-admin')) {
      top_nav_admin = 'active';
    } else if (url.includes('sort-and-filter')) {
      sort_and_filter = 'active';
    } else if (url.includes('/community/moderation')) {
      communityModeration = 'active';
    } else if (url.includes('agent-dashboard')) {
      agent_dashboard = 'active';
    } else if (url.includes('/community/upload')) {
      communityUpload = 'active';
    } else if (url.includes('/supply-tasks/uat-catalogs')) {
      uat_catalogs = 'active';
    } else if (url.includes('/hvf-admin')) {
      hvf_admin = 'active';
    } else if (url.includes('/farmiso/dealer-activate')) {
      farmiso_dealer_activate = 'active';
    } else if (url.includes('/verify-kyc')) {
      supplier_finance = 'active';
    } else if (url.includes('/fulfilment')) {
      fulfilment = 'active';
    } else if (url.includes('all_notification_campaigns')) {
      all_notification_campaigns = 'active';
    } else if (url.includes('/ads-task')) {
      supplier_ads_task = 'active';
    } else if(url.includes('/mma-task')){
      mma_link = 'active';
    } else if (url.includes('/live-commerce')) {
      live_stream_view = 'active';
    } else if (
      url.includes('/campaign-dashboard') ||
      url.includes('/display-awareness') ||
      url.includes('/create-new-campaign')
    ) {
      dispaly_ads_section = 'active';
    } else if (url.includes('/non-seller-portal')) {
      non_seller_portal = 'active';
    }

    return (
      <nav
        className="navbar navbar-default navbar-static-top justify-content-between"
        role="navigation"
      >
        {!!this.props.navBarVisible && (
          <div className="flex-center">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target=".navbar-collapse"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              <Link to="/" className="navbar-brand">
                Meesho Supply
              </Link>
            </div>
            <div className="toggle-button">
              <div onClick={this.toggleSideBar.bind(this)} id="menu_toggle">
                <i className="fa fa-bars" />
              </div>
            </div>
          </div>
        )}
        <div className="flex">
          {this.state.order_page &&
            this.state.orderFilter &&
            parseInt(this.state.order_count) > 0 && (
            <div className="orderCount">
              {Globalize.getString('order_count')} = {this.state.order_count}{' '}
                & {Globalize.getString('sub_order_count')} ={' '}
              {this.state.product_count}
            </div>
          )}
          <ul className="nav navbar-top-links navbar-right flex-center">
            {this.state.order_page ||
              (this.state.catalog_page && (
                <li>
                  <button
                    onClick={this.toggleFilter.bind(this)}
                    className="filter-hide-button border"
                  >
                    {this.state.filter_text}
                  </button>
                </li>
              ))}
            {this.props.user !== undefined &&
              this.props.user.url !== undefined && (
              <li className="user-name">{this.props.user.url}</li>
            )}
            <li className="user-name">
              <span>
                {this.props.user !== undefined &&
                this.props.user.email !== undefined
                  ? this.props.user.email
                  : ''}
              </span>
              {this.props.user && this.props.user.country !== undefined ? (
                <span> ({this.props.user.country.name})</span>
              ) : (
                ''
              )}
            </li>
            {/*{getUserPageAccess(this.props.user_roles, ['user_settings']) &&
          <li>
            <Link to={'/settings'} className='bold'>Settings</Link>
          </li>
          }*/}
            <li className="user-name">
              <Link
                className="bold"
                onClick={this.logout.bind(this)}
                to="/login"
              >
                {Globalize.getString('logout')}
              </Link>
            </li>
          </ul>
        </div>
        <div
          className={
            'navbar-default sidebar sidebar-open' +
            (this.state.sidebarOpen ? '' : ' sidebar-close')
          }
          role="navigation"
        >
          <div className="sidebar-nav navbar-collapse">
            <ul className="nav in" id="side-menu">
              {getUserPageAccess(this.props.user_roles, ['suppliers_view']) && (
                <li>
                  <Link to="/suppliers" className={this.state.supplier}>
                    <i className="fa fa-building-o fa-fw" />{' '}
                    {Globalize.getString('suppliers')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, ['catalogs_view']) && (
                <li>
                  <a
                    href="/v3/cataloging/catalogs"
                    className={this.state.catalog}
                  >
                    <i className="fa fa-database fa-fw" />{' '}
                    {Globalize.getString('catalogs')}
                  </a>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'collections_view',
              ]) && (
                <li>
                  <Link
                    to="/collections"

                    className={collections}
                  >
                    <i className="fa fa-database fa-fw" />{' '}
                    {Globalize.getString('collections')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, ['hvf_admin']) && (
                <li>
                  <Link
                    to="/hvf-admin"

                    className={hvf_admin}
                  >
                    <i className="fa fa-filter fa-fw" />{' '}
                    {Globalize.getString('hvf_admin')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'community_moderation',
              ]) && (
                <li>
                  <Link
                    to="/community/moderation"

                    className={communityModeration}
                  >
                    <i className="fa fa-file fa-fw" />{' '}
                    {Globalize.getString('community_moderation')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'community_upload',
              ]) && (
                <li>
                  <Link
                    to="/community/upload"

                    className={communityUpload}
                  >
                    <i className="fa fa-upload" />{' '}
                    {Globalize.getString('community_upload')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, ['widgets_view']) && (
                <li>
                  <Link
                    to="/widgets-group"

                    className={widgets}
                  >
                    <i className="fa fa-file-excel-o fa-fw" /> Widgets Group
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'landing_pages_view',
              ]) && (
                <li>
                  <Link
                    to="/landing-pages"

                    className={landing_pages}
                  >
                    <i className="fa fa-pagelines" />{' '}
                    {Globalize.getString('landing_pages')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, ['orders_view']) && (
                <li>
                  <Link
                    to="/orders"

                    className={this.state.order}
                  >
                    <i className="fa fa-shopping-cart fa-fw" />{' '}
                    {Globalize.getString('orders')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, ['resellers_view']) && (
                <li>
                  <a
                    href="/v3/reseller"
                    className={reseller}
                  >
                    <i className="fa fa-building-o fa-fw" />{' '}
                    {Globalize.getString('reseller')}
                  </a>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'supplier_payments_view',
              ]) && (
                <li>
                  <Link
                    to="/supplier/payment"

                    className={supplierPayment}
                  >
                    <i className="fa fa-building-o fa-fw" />{' '}
                    {Globalize.getString('supplier_payment')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'app_banners_view',
              ]) && (
                <li>
                  <Link
                    to="/app-banners"

                    className={banners}
                  >
                    <i className="fa fa-image fa-fw" />{' '}
                    {Globalize.getString('app_banners')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'cod_verification_view',
              ]) && (
                <li>
                  <Link
                    to="/codverification"

                    className={cod_verification}
                  >
                    <i className="fa fa-file-excel-o fa-fw" />{' '}
                    {Globalize.getString('cod_verification')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, ['trends_view']) && (
                <li>
                  <Link
                    to="/dailytrends"

                    className={daily_trends}
                  >
                    <i className="fa fa-file-excel-o fa-fw" />{' '}
                    {Globalize.getString('daily_trends')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'bulk_cancellations_view',
              ]) && (
                <li>
                  <Link
                    to="/bulkcancellation"

                    className={bulk_cancellation}
                  >
                    <i className="fa fa-file-excel-o fa-fw" />{' '}
                    {Globalize.getString('bulk_cancellation')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, ['firebase_admin']) && (
                <li>
                  <Link
                    to="/firebase/admin"

                    className={firebase_admin}
                  >
                    <i className="fa fa-user-circle-o" />{' '}
                    {Globalize.getString('firebase_admin')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'supplier_invoice',
              ]) && (
                <li>
                  <Link
                    to="/tax-invoices"

                    className={tax_invoices}
                  >
                    <i className="fa fa-file-excel-o fa-fw" />{' '}
                    {Globalize.getString('supplier_tax_invoices')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'supplier_penalty',
              ]) && (
                <li>
                  <Link
                    to="/supplier-penalty-old"

                    className={supplier_penalty}
                  >
                    <i className="fa fa-file-excel-o fa-fw" /> Supplier Penalty
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, ['supply_tasks']) && (
                <li>
                  <Link
                    to="/supply-tasks"

                    className={supply_tasks}
                  >
                    <i className="fa fa-file-excel-o fa-fw" />{' '}
                    {Globalize.getString('supply_tasks')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'user_access_admin',
              ]) && (
                <li>
                  <Link
                    to="/admin-access"

                    className={admin_access}
                  >
                    <i className="fa fa-file-excel-o fa-fw" />{' '}
                    {Globalize.getString('admin_access')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, ['admin_notice']) && (
                <li>
                  <Link
                    to="/admin-notices"

                    className={admin_notice}
                  >
                    <i className="fa fa-file-excel-o fa-fw" />{' '}
                    {Globalize.getString('admin_notices')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'catalog_rating_review',
              ]) && (
                <li>
                  <Link
                    to="/review-ratings"

                    className={review_ratings}
                  >
                    <i className="fa fa-file-excel-o fa-fw" />{' '}
                    {Globalize.getString('review_ratings')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'video_influencer_review',
              ]) && (
                <li>
                  <Link
                    to="/video-influencer"

                    className={video_influencer}
                  >
                    <i className="fa fa-file-excel-o fa-fw" />{' '}
                    {Globalize.getString('video_influencer')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'dynamic_webview',
              ]) && (
                <li>
                  <Link
                    to="/dynamic-webview"

                    className={dynamic_webview}
                  >
                    <i className="fa fa-file-excel-o fa-fw" />{' '}
                    {Globalize.getString('dynamic_webview')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, ['pricing_deals']) && (
                <li>
                  <Link to="/deals"  className={deals}>
                    <i className="fa fa-user-circle-o" />{' '}
                    {Globalize.getString('deals')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'price_scheduling',
              ]) && (
                <li>
                  <Link
                    to="/price-scheduling"

                    className={price_scheduling}
                  >
                    <i className="fa fa-user-circle-o" />{' '}
                    {Globalize.getString('price_scheduling')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'catalog_rating_file_upload',
              ]) && (
                <li>
                  <Link
                    to="/catalog-rating-rewards"

                    className={catalog_ratings}
                  >
                    <i className="fa fa-file-excel-o fa-fw" />{' '}
                    {Globalize.getString('tinder_ratings')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'meesho_reseller_rewards',
              ]) && (
                <li>
                  <Link
                    to="/meesho-rewards"

                    className={reseller_tiering}
                  >
                    <i className="fa fa-file-excel-o fa-fw" />{' '}
                    {Globalize.getString('meesho_rewards')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'mentor_performance_dashboard',
              ]) && (
                <li>
                  <Link
                    to="/mentor-dashboard"

                    className={reseller_tiering}
                  >
                    <i className="fa fa-file-excel-o fa-fw" />{' '}
                    {Globalize.getString('mentor_dashboard')}
                  </Link>
                </li>
              )}
              {/* {getUserPageAccess(this.props.user_roles, ['supplier_inventory_update']) && <li>
              <Link to="/supplier-inventory-update"  className={supplier_inventory_update}>
                <i className="fa fa-file-excel-o fa-fw"/> {Globalize.getString('supplier_inventory_update')}
              </Link>
            </li>} */}
              {getUserPageAccess(this.props.user_roles, [
                'supplier_manifest',
              ]) && (
                <li>
                  <Link
                    to="/supplier-manifest"

                    className={supplier_manifest}
                  >
                    <i className="fa fa-file-excel-o fa-fw" />{' '}
                    {Globalize.getString('supplier_manifest')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'files_uploading',
              ]) && (
                <li>
                  <Link
                    to="/files-uploading"

                    className={files_uploading}
                  >
                    <i className="fa fa-file-excel-o fa-fw" />{' '}
                    {Globalize.getString('file_upload')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, ['visual_search']) && (
                <li>
                  <a
                    href="/v3/discovery/visual-search"

                    className={visual_search}
                  >
                    <i className="fa fa-file-excel-o fa-fw" />{' '}
                    {Globalize.getString('visual_search')}
                  </a>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, ['update_pow_static_assets']) && (
                <li>
                  <a
                    href="/v3/discovery/update-static-assets"
                  >
                    <i className="fa fa-file-excel-o fa-fw" />{' '}
                    {Globalize.getString('update_pow_static_assets')}
                  </a>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, ['ab_experiment']) && (
                <li>
                  <Link
                    to="/ab-experiment"

                    className={ab_experiment}
                  >
                    <i className="fa fa-file-excel-o fa-fw" />{' '}
                    {Globalize.getString('ab_experiment')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, ['abacus']) && (
                <li>
                  <a href="/v3/discovery/abacus">
                    <i className="fa fa-file-excel-o fa-fw" />
                    {Globalize.getString('abacus')}
                  </a>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'audience_system',
              ]) && (
                <li>
                  <Link
                    to="/audience-system"

                    className={audience_system}
                  >
                    <i className="fa fa-file-excel-o fa-fw" />{' '}
                    {Globalize.getString('audience_system')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'marketing_dashboard',
              ]) && (
                <li>
                  <Link
                    to="/marketing-dashboard"

                    className={marketing_dashboard}
                  >
                    <i className="fa fa-file-excel-o fa-fw" />{' '}
                    {Globalize.getString('marketing_dashboard')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'sms_portal_view',
              ]) && (
                <li>
                  <a
                    href="/v3/comms/sms"
                    className={sms_portal}
                  >
                    <i className="fa fa-database fa-fw" />
                    {Globalize.getString('sms_portal')}
                  </a>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'notification_portal_view',
              ]) && (
                <li>
                  <a
                    href="/v3/comms/notification"
                    className={notification_portal}
                  >
                    <i className="fa fa-database fa-fw" />
                    {Globalize.getString('notification_portal')}
                  </a>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'whatsapp_notifications_view',
              ]) && (
                <li>
                  <a
                    href="/v3/comms/whatsapp"
                    className={whatsapp_notifications}
                  >
                    <i className="fa fa-database fa-fw" />
                    {Globalize.getString('whatsapp_notification')}
                  </a>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'email_notifications'
              ]) && (
                <li>
                  <a href="/v2/email-notifications">
                    <i className="fa fa-database fa-fw" />
                    {Globalize.getString('email_notifications')}
                  </a>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'notification_all_campaigns',
              ]) && (
                <li>
                  <a
                    href="/v3/comms/all-notifications"
                    className={all_notification_campaigns}
                  >
                    <i className="fa fa-database fa-fw" />{' '}
                    {Globalize.getString('all_notification_campaigns')}
                  </a>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'make_calls_view',
              ]) && (
                <li>
                  <Link
                    to="/make-calls"

                    className={make_calls}
                  >
                    <i className="fa fa-database fa-fw" />{' '}
                    {Globalize.getString('make_calls')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, ['gamification']) && (
                <li>
                  <Link
                    to="/gamification"

                    className={gamification}
                  >
                    <i className="fa fa-file-excel-o fa-fw" />{' '}
                    {Globalize.getString('gamification')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'referral_campaign',
              ]) && (
                <li>
                  <Link
                    to="/referral_campaign"

                    className={referral_campaign}
                  >
                    <i className="fa fa-file-excel-o fa-fw" /> Referral Campaign
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'referral_program',
              ]) && (
                <li>
                  <Link
                    to="/referral_program"

                    className={referral_program}
                  >
                    <i className="fa fa-file-excel-o fa-fw" />{' '}
                    {Globalize.getString('referral_program')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                's3_file_uploader',
              ]) && (
                <li>
                  <Link
                    to="/s3-file-uploader"

                    className={s3_file_uploader}
                  >
                    <i className="fa fa-file-excel-o fa-fw" />{' '}
                    {Globalize.getString('s3_file_uploader')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'generic_excel_upload',
              ]) && (
                <li>
                  <Link
                    to="/excel-upload"
                    onClick={this.onResExcelDownload.bind(this)}

                    className={add_attributes_view}
                  >
                    <i className="fa fa-file-excel-o fa-fw" />{' '}
                    {Globalize.getString('excel_upload')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, ['rule_engine']) && (
                <li>
                  <Link
                    to="/rule-engine"

                    className={rule_engine}
                  >
                    <i className="fa fa-file-excel-o fa-fw" />{' '}
                    {Globalize.getString('rule_engine')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, ['search_ops']) && (
                <li>
                  <a href="/v3/discovery/search-ops">
                    <i className="fa fa-search fa-fw" />{' '}
                    {Globalize.getString('search_ops')}
                  </a>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'category_navigation_trees',
              ]) && (
                <li>
                  <Link
                    to="/category-navigation"

                    className={category_navigation_trees}
                  >
                    <i className="fa fa-file-excel-o fa-fw" />{' '}
                    {Globalize.getString('category_navigation_trees')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, ['clp_admin']) && (
                <li>
                  <Link
                    to="/clp-admin"

                    className={clp_admin}
                  >
                    <i className="fa fa-file-excel-o fa-fw" />{' '}
                    {Globalize.getString('clp_admin')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'clp_products_view',
              ]) && (
                <li>
                  <Link
                    to="/clp/products"

                    className={clp_products_view}
                  >
                    <i className="fa fa-file-excel-o fa-fw" /> CLP Filters
                    Validation
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, ['top_nav_admin']) && (
                <li>
                  <Link
                    to="/top-nav-admin"

                    className={top_nav_admin}
                  >
                    <i className="fa fa-file-excel-o fa-fw" /> Top NAV Admin
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'sort_and_filter',
              ]) && (
                <li>
                  <Link
                    to="/sort-and-filter"

                    className={sort_and_filter}
                  >
                    <i className="fa fa-file-excel-o fa-fw" /> Sort and Filter
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'agent_dashboard',
              ]) && (
                <li>
                  <Link
                    to="/agent-dashboard/search"

                    className={agent_dashboard}
                  >
                    <i className="fa fa-file-excel-o fa-fw" />{' '}
                    {Globalize.getString('agent_dashboard')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, ['uat_catalogs']) && (
                <li>
                  <Link
                    to="/supply-tasks/uat-catalogs"

                    className={uat_catalogs}
                  >
                    <i className="fa fa-file-excel-o fa-fw" />{' '}
                    {Globalize.getString('uat_catalogs')}
                  </Link>
                </li>
              )}
              {/*{getUserPageAccess(this.props.user_roles, [
                'farmiso_dealer_activate',
              ]) && (
                <li>
                  <Link
                    to="/farmiso/dealer-activate"

                    className={farmiso_dealer_activate}
                  >
                    <i className="fa fa-user-circle" /> Farmiso Dealer Activate
                  </Link>
                </li>
              )}*/}
              {getUserPageAccess(this.props.user_roles, [
                'supplier_finance',
              ]) && (
                <li>
                  <Link
                    to="/verify-kyc"

                    className={supplier_finance}
                  >
                    <i className="fa fa-building-o fa-fw" />{' '}
                    {Globalize.getString('supplier_finance')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, ['search_ops']) && (
                <li>
                  <Link
                    to="/fulfilment"

                    className={fulfilment}
                  >
                    <i className="fa fa-building-o fa-fw" />{' '}
                    {Globalize.getString('return_reasons')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'supplier_ads_task',
              ]) && (
                <li>
                  <Link
                    to="/ads-task"

                    className={supplier_ads_task}
                  >
                    <i className="fa fa-building-o fa-fw" />{' '}
                    {Globalize.getString('supplier_ads_task')}
                  </Link>
                </li>
              )}

              {getUserPageAccess(this.props.user_roles, [
                'supplier_ads_task_mma_deep_link',
              ]) && (
                <li>
                  <Link
                    to="/mma-task"
                    className={mma_link}
                  >
                    <i className="fa fa-building-o fa-fw" />{' '}
                     MMA task
                  </Link>
                </li>
              )}

              {getUserPageAccess(this.props.user_roles, [
                'live_stream_view',
              ]) && (
                <li>
                  <Link
                    to="/live-commerce"
                    className={live_stream_view}
                  >
                    <i className="fa fa-building-o fa-fw" />{' '}
                    {Globalize.getString('live_commerce')}
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'promotions_view',
              ]) && (
                <li>
                  <a href="/v3/promotions">
                    <i className="fa fa-percent fa-fw" />
                    {Globalize.getString('promotions')}
                  </a>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'agency_panel_account_management',
              ]) && (
                <li>
                  <a href="/v2/agency-account-management">
                    <i className="fa fa-database fa-fw" />
                    {Globalize.getString('agency_panel_account_management')}
                  </a>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, ['admin_queries']) && (
                <li>
                  <a href="/v2/admin-queries">
                    <i className="fa fa-database fa-fw" />
                    {Globalize.getString('admin_queries')}
                  </a>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, ['agent_queries']) && (
                <li>
                  <a href="/v2/agent-queries">
                    <i className="fa fa-database fa-fw" />
                    {Globalize.getString('agent_queries')}
                  </a>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, ['ds_tagging']) && (
                <li>
                  <a href="/v2/ds-tagging">
                    <i className="fa fa-database fa-fw" />
                    {Globalize.getString('ds_tagging')}
                  </a>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'bulk_password_reset',
              ]) && (
                <li>
                  <a href="/v2/password-reset-bulk">
                    <i className="fa fa-database fa-fw" />
                    {Globalize.getString('bulk_password_reset')}
                  </a>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'dispaly_ads_section',
              ]) && (
                <li>
                  <Link
                    to="/campaign-dashboard"

                    className={dispaly_ads_section}
                  >
                    <i className="fa fa-database fa-fw" />
                    Display Ads
                  </Link>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'bulk_gstin_invalidate',
              ]) && (
                <li>
                  <a href="/v2/gstin-invalidate-bulk">
                    <i className="fa fa-database fa-fw" />
                    {Globalize.getString('bulk_gstin_invalidate')}
                  </a>
                </li>
              )}
              {
                getUserPageAccess(this.props.user_roles, ['dispaly_ads_section']) && (
                  <li>
                    <Link
                      to="/non-seller-portal"

                      className={non_seller_portal}
                    >
                      <i className="fa fa-database fa-fw" />
                    Non Seller Portal
                    </Link>
                  </li>
                )
              }
              {getUserPageAccess(this.props.user_roles, [
                'supplier_panel_access_control',
              ]) && (
                <li>
                  <a href="/v3/cataloging/role-based-access">
                    <i className="fa fa-database fa-fw" />
                    {Globalize.getString('supplier_role_based_access')}
                  </a>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'send_supplier_email',
              ]) && (
                <li>
                  <a href="/v3/cataloging/supplier-email">
                    <i className="fa fa-database fa-fw" />
                    {Globalize.getString('send_supplier_email')}
                  </a>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'zero_order_audience',
              ]) && (
                <li>
                  <a href="/v3/discovery/zero-order-audience">
                    <i className="fa fa-users fa-fw" />{' '}
                    {Globalize.getString('zero_order_audience')}
                  </a>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'single_product_details_admin',
              ]) && (
                <li>
                  <a href="/v3/discovery/single-product-details">
                    <i className="fa fa-product-hunt fa-fw" />{' '}
                    {Globalize.getString('single_product_details')}
                  </a>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'widgets_view',
              ]) && (
                <li>
                  <a href="/v3/discovery/debug-your-widget-group">
                    <i className="fa fa-users fa-fw" />{' '}
                    {Globalize.getString('debug_your_widget_group')}
                  </a>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'widgets_view',
              ]) && (
                <li>
                  <a href="/v3/discovery/widget-forge">
                    <i className="fa fa-gear fa-fw" />{' '}
                    {Globalize.getString('sdui_widget_forge')}
                  </a>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'widgets_view',
              ]) && (
                <li>
                  <a href="/v3/discovery/sdui/widget-preview">
                    <i className="fa fa-camera-retro fa-fw" />{' '}
                    {Globalize.getString('sdui_widget_preview')}
                  </a>
                </li>
              )}
              {getUserPageAccess(this.props.user_roles, [
                'sdui_widget_template',
              ]) && (
                <li>
                  <a href="/v3/discovery/sdui/templates">
                    <i className="fa fa-database fa-fw" />{' '}
                    {Globalize.getString('sdui_widget_template')}
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default connect(null, mapDispatchToProps, null, { withRef: true })(
  DashBoardNavigation
);
